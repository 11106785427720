

























import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import { moduleTypes } from '@/store/types';
import TheFooter from '@/components/layouts/default/TheFooter.vue';
import TheAppBar from '@/components/layouts/default/TheAppBar.vue';

const loginNamespace = namespace(moduleTypes.LOGIN);
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const homeNamespace = namespace(moduleTypes.HOME);
import { actionTypes as homeActionsTypes } from '@/store/modules/home/types';

@Component({
	components: { TheFooter, TheAppBar }
})
export default class DefaultLayout extends Vue {
	@loginNamespace.Getter(loginGetterTypes.IS_EDITOR) isEditor: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_ADMIN) isAdmin: boolean;

	@homeNamespace.Action(homeActionsTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;

	async mounted() {
		// console.log('loadPage');
		await this.loadTexts();
	}
}
