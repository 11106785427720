


























































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
const homeNamespace = namespace(moduleTypes.HOME);
import {
	getterTypes as homeGettersTypes,
	actionTypes as homeActionsTypes
} from '@/store/modules/home/types';
import TextEditor from '@/components/TextEditor.vue';
import { TextData } from '@/services';
import FileReplacer from '@/components/file/FileReplacer.vue';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
import TextService from '@/services/textService';
import { POSITION } from 'vue-toastification';
import FileService from '@/services/fileService';
const loginNamespace = namespace(moduleTypes.LOGIN);

@Component({
	components: { TextEditor, FileReplacer }
})
export default class Kurzy extends Vue {
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;
	@homeNamespace.Action(homeActionsTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	textField(key: string) {
		return this.textByKey(key);
	}

	get numberOfCurses() {
		return +(this.textByKey('numberOfCurses')?.value ?? 0);
	}

	get curses() {
		const curses: {
			title: TextData | null;
			text: TextData | null;
			photo: TextData | null;
		}[] = [];
		for (let i = 1; i <= this.numberOfCurses; i++) {
			if (this.textField(`kurzTitle${i}`)?.value !== 'deletedByUser') {
				curses.push({
					title: this.textField(`kurzTitle${i}`),
					text: this.textField(`kurzText${i}`),
					photo: this.textField(`kurzyPhoto${i}`)
				});
			}
		}
		return curses;
	}

	async updateText(data: TextData) {
		await TextService.updateTextById(data._id, data).then(async res => {
			// console.log(res);
			this.$toast.success('Kurz pridaný', {
				position: POSITION.BOTTOM_RIGHT,
				timeout: 2000
			});
			await this.loadTexts();
		});
	}

	async addNewKurz() {
		const numberOfCurses = this.textField('numberOfCurses');
		if (numberOfCurses) {
			numberOfCurses.value = `${this.numberOfCurses + 1}`;
			await this.updateText(numberOfCurses);
		}
	}

	async deleteCurs(textDataTitle: TextData, textDataText: TextData, textDataPhoto: TextData) {
		await FileService.deleteFile(textDataPhoto.value); // delete photo
		await TextService.deleteText(textDataText._id); // remove popis
		await TextService.deleteText(textDataPhoto._id); // remove link to photo
		textDataTitle.value = 'deletedByUser';
		await this.updateText(textDataTitle);
	}
}
