










































































































































import { Component, Vue } from 'vue-property-decorator';
import { getterTypes as homeGettersTypes } from '@/store/modules/home/types';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
import { TextData, CennikCategory } from '@/services';
import TextEditor from '@/components/TextEditor.vue';
import CennikService, { CategoryParam } from '@/services/cennikService';
import moment from 'moment';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: { TextEditor }
})
export default class Cennik extends Vue {
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	categories: (CennikCategory & { editTitle: boolean; showAddItem: boolean })[] = [];

	newCategoryTitle = '';
	newItemText = '';
	newItemValue = '';

	async getCategories() {
		CennikService.getCategories().then(response => {
			this.categories = [];
			if (response) {
				// console.log(response);
				Object.keys(response).forEach(key => {
					this.categories.push(
						Object.assign(response[key], { editTitle: false, showAddItem: false })
					);
					this.categories.sort(
						(a, b) =>
							new Date(a.metadata.trackingInfo.created).getTime() -
							new Date(b.metadata.trackingInfo.created).getTime()
					);
				});
			}
		});
	}

	async addCategory() {
		await CennikService.setCategory(
			CennikCategory.create({
				title: this.newCategoryTitle,
				params: []
			})
		).then(async response => {
			// console.log(response);
			this.newCategoryTitle = '';
			await this.getCategories();
		});
	}

	async editTitle(categoryId: string) {
		const category = this.categories.find(c => c._id === categoryId);
		if (category) {
			await CennikService.updateCategoryById(category._id, category).then(async response => {
				await this.getCategories();
			});
		}
	}

	async addItem(categoryId: string) {
		const category = this.categories.find(c => c._id === categoryId);
		if (category && this.newItemText && this.newItemValue) {
			category.params = category.params ?? [];
			category.params.push({ text: this.newItemText, value: this.newItemValue });
			await CennikService.updateCategoryById(category._id, category).then(async response => {
				this.newItemValue = '';
				this.newItemText = '';
				await this.getCategories();
			});
		}
	}

	async deleteItem(categoryId: string, item: CategoryParam) {
		const category = this.categories.find(c => c._id === categoryId);
		if (category) {
			category.params = category.params ?? [];
			category.params = category.params.filter(p => p !== item);
			await CennikService.updateCategoryById(category._id, category).then(async response => {
				await this.getCategories();
			});
		}
	}

	async deleteCategory(id: string) {
		await CennikService.deleteCategory(id).then(async () => {
			// console.log('OK');
			await this.getCategories();
		});
	}

	makeups = [
		{
			text: 'Denný make-up',
			value: '25€'
		},
		{
			text: 'Spoločenský make-up',
			value: '35€'
		},
		{
			text: 'Svadobný makeup',
			value: '45€'
		},
		{
			text: 'Makeup pre hosťa na realizovanej svadbe',
			value: '35€'
		},
		{
			text: 'Make-up pre ženícha (Na fotenie)',
			value: '8€'
		},
		{
			text: 'Dočasné prekrytie tetovania',
			value: '5 - 20€'
		}
	];
	ucesy = [
		{
			text: 'Jednoduchý účes (vlny,žehlenie)',
			value: '15€'
		},
		{
			text: 'Svadobný /spoločenský',
			value: '25€'
		},
		{
			text: 'Zapožičanie ozdoby do vlasov',
			value: '10€ + záloha ktorá bude zaslaná späť po návrate doplnku.'
		},
		{
			text: 'Mužský účes',
			value: '5€'
		},
		{
			text: 'Úprava brady',
			value: '15 - 20€'
		}
	];
	svadby = [
		{
			text: 'Makeup + Účes',
			value: '65€'
		},
		{
			text: 'Rezervačný poplatok',
			value: '20€ (Z výslednej sumy bude odpočítaný)'
		}
	];

	textField(key: string) {
		return this.textByKey(key);
	}

	async mounted() {
		await this.getCategories();
	}
}
