import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
	const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages: LocaleMessages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}

const localeMessages = loadLocaleMessages();

const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'sk-SK',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'sk-SK',
	messages: localeMessages,
	pluralizationRules: {
		//   'cs-CZ': (choice: number, choicesLength: number) => {
		//     if (choicesLength === 3) {
		//       return choice > 4 ? 2 : choice > 0 ? 1 : 0;
		//     } else if (choicesLength > 1 && choice < choicesLength) {
		//       return choice;
		//     } else if (choicesLength > 1) {
		//       // get last available
		//       return choicesLength - 1;
		//     }
		//     return 0;
		//   },
		'sk-SK': (choice: number, choicesLength: number) => {
			if (choicesLength === 3) {
				return choice > 4 ? 2 : choice > 0 ? 1 : 0;
			} else if (choicesLength > 1 && choice < choicesLength) {
				return choice;
			} else if (choicesLength > 1) {
				// get last available
				return choicesLength - 1;
			}
			return 0;
		}
	}
});

export default i18n;
