import { Module } from 'vuex';
import { getters } from '@/store/modules/layout/getters';
import { mutations } from '@/store/modules/layout/mutations';
import { LayoutState } from '@/store/modules/layout/types';
import { RootState } from '@/store/types';
import { layoutTypes } from '@/services/types/layoutTypes';

export const state: LayoutState = {
	layout: layoutTypes.DEFAULT,
	loading: false
};

export const layout: Module<LayoutState, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations
};
