import { getters } from '@/store/modules/login/getters';
import { mutations } from '@/store/modules/login/mutations';
import { actions } from '@/store/modules/login/actions';
import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { LoginState } from './types';

export const state: LoginState = {
	isLogged: false,
	resetPassword: false,
	email: '',
	isAdmin: false,
	isEditor: false,
	isPostman: false,
	isBasic: false,
	isActive: true,
	lastLogin: '',
	numberOfIncorrectLogins: 0
};

export const login: Module<LoginState, RootState> = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};
