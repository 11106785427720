import FirebaseService from './firebaseService';
import { BaseEntity, createGuid, Metadata, TrackingInfo } from './types';

export enum TablesNamesText {
	TEXT = 'text'
}

export class TextData extends BaseEntity {
	key: string;
	value: string;

	constructor(data?: Partial<TextData>) {
		super();
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}

	static create(data: Partial<TextData>): TextData {
		return new TextData({
			_id: data._id ?? createGuid(),
			metadata:
				data.metadata ||
				new Metadata({
					type: TextData.name,
					trackingInfo: new TrackingInfo({ created: new Date().toDateString() })
				}),
			key: data.key,
			value: data.value
		});
	}
}

export default class TextService extends FirebaseService {
	/////////////////////////////////////////
	//                Albums               //
	/////////////////////////////////////////

	static getTexts() {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesText.TEXT}`
		);
	}

	static getTextById(id: string) {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesText.TEXT}/${id}`
		);
	}

	static setText(data: TextData) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesText.TEXT}/${data._id}`,
			data
		);
	}
	static deleteText(textId: string) {
		return this.removeData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesText.TEXT}/${textId}`
		);
	}
	static updateTextById(id: string, data: Partial<TextData>) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesText.TEXT}/${id}`,
			data
		);
	}
}
