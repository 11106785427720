import { actionTypes, mutationTypes, HomeState } from './types';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import TextService, { TextData } from '@/services/textService';

export const actions: ActionTree<HomeState, RootState> = {
	async [actionTypes.LOAD_TEXTS]({ commit, state }): Promise<void> {
		await TextService.getTexts().then(response => {
			// console.log(response);
			const data: TextData[] = [];
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			commit(mutationTypes.SAVE_TEXTS, data);
		});
	}
};
