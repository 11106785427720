export interface WebInfoState {
	link: string;
	mail: string;
	name: string;
	phone?: string;
	facebook?: string;
	instagram?: string;
	startYear: number;
}

export const actionTypes = {};

export const mutationTypes = {};

export const getterTypes = {
	WEB_INFO: 'webInfo'
};
