export enum Roles {
	ADMIN = 'admin',
	EDITOR = 'editor',
	POSTMAN = 'postman',
	BASIC = 'basic'
}

export class User {
	email: string;
	role: Roles;
	lastLogin?: string;
	passwordReset: boolean;
	active: boolean;

	constructor(data?: Partial<User>) {
		Object.assign(this, data);
	}

	static create(data: Partial<User>): User {
		return new User({
			email: data.email,
			role: Roles.BASIC,
			passwordReset: true,
			active: true
		});
	}

	static from(data: Partial<User>): User {
		return new User({
			email: data.email,
			role: data.role || Roles.BASIC,
			lastLogin: data.lastLogin || '',
			passwordReset: data.passwordReset || false,
			active: data.active !== undefined ? data.active : true
		});
	}
}
