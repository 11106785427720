import { BaseEntity, createGuid, Metadata } from './baseTypes';

export class FileUploadInfo {
	size: boolean;
	extension: boolean;
	type: boolean;
	constructor(data?: Partial<FileUploadInfo>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
}

export class FileUploadResponse {
	fileUpload: boolean;
	fileInfo: FileUploadInfo;

	constructor(data?: Partial<FileUploadResponse>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
}

export class PhotoFile extends BaseEntity {
	link: string;
	title: string;
	size: string;
	height: number;
	width: number;
	albumId: string;

	constructor(data?: Partial<PhotoFile>) {
		super();
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
	static create(data: Partial<PhotoFile>): PhotoFile {
		return new PhotoFile({
			_id: data._id ?? createGuid(),
			metadata: data.metadata || new Metadata({ type: PhotoFile.name }),
			link: data.link,
			title: data.title,
			size: data.size,
			width: data.width,
			height: data.height,
			albumId: data.albumId
		});
	}
}

export class Album extends BaseEntity {
	title: string;
	numberOfPhotos: number;
	photos: PhotoFile[];

	constructor(data?: Partial<Album>) {
		super();
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
	static create(data: Partial<Album>): Album {
		return new Album({
			_id: data._id ?? createGuid(),
			metadata: data.metadata || new Metadata({ type: Album.name }),
			title: data.title,
			numberOfPhotos: data.numberOfPhotos,
			photos: data.photos ?? []
		});
	}
}
