



















































































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import UserService from '../../services/usersService';
import { TranslateResult } from 'vue-i18n';
import { moduleTypes } from '../../store/types';

const loginNamespace = namespace(moduleTypes.LOGIN);
const webInfoNamespace = namespace(moduleTypes.WEB_INFO);

import { getterTypes as webInfoGetterTypes, WebInfoState } from '../../store/modules/webInfo/types';
import {
	getterTypes as loginGetterTypes,
	actionTypes as loginActionTypes
} from '@/store/modules/login/types';
import { FirebaseError } from 'firebase';

@Component
export default class LoginForm extends Vue {
	@Prop({ default: false }) showLogin: boolean;
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;

	@loginNamespace.Action(loginActionTypes.TOGGLE_LOGIN) toggleLogin: any;
	@loginNamespace.Getter(loginGetterTypes.NUMBER_OF_INCORRECT_LOGINS)
	numberOfIncorrectLogins: number;
	@loginNamespace.Action(loginActionTypes.CHANGE_NUMBER_OF_INCORRECT_LOGINS)
	changeNumberOfIncorrectLogins: any;

	valid = true;
	dialog = false;
	errorMsg: TranslateResult = '';
	loginLoader = false;
	email = '';
	password = '';
	emailRules = [
		(v: string) => !!v || 'Toto pole je povinné',
		(v: string) => /.+@.+/.test(v) || 'Formát e-mailu nie je správny'
	];
	rules = [(v: string) => !!v || 'Toto pole je povinné'];

	mounted() {
		if (this.showLogin) {
			this.dialog = true;
		}
	}

	login() {
		if ((this.$refs.loginForm as Vue & { validate: () => boolean }).validate()) {
			this.loginLoader = true;
			this.errorMsg = '';
			UserService.loginFirebase(this.email, this.password)
				.then(resp => {
					this.loginLoader = false;
					this.toggleLogin({ id: resp.user!.uid, email: this.email });
					this.email = '';
					this.password = '';
					this.dialog = false;
					window.scrollTo(0, 0);
					this.$emit('loginSuccessful');
				})
				.catch(error => {
					this.loginLoader = false;
					this.changeNumberOfIncorrectLogins(this.numberOfIncorrectLogins + 1);
					switch (error.code) {
						case 'auth/too-many-requests':
							this.errorMsg = this.$t(error.code);
							break;
						default:
							this.errorMsg = this.$t('login.unsuccessful');
							break;
					}
				});
		}
	}
}
