








































































import { Component, Vue } from 'vue-property-decorator';
import { getterTypes as webInfoGettersTypes, WebInfoState } from '@/store/modules/webInfo/types';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';
import ContactsService from '@/services/mailService';
import { SendMailRequest, TextData } from '@/services';
import { getterTypes as homeGettersTypes } from '@/store/modules/home/types';

const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: {}
})
export default class Objednavky extends Vue {
	@webInfoNamespace.Getter(webInfoGettersTypes.WEB_INFO) webInfo: WebInfoState;
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;

	valid = false;
	firstname = '';
	isLoading = false;
	nameRules = [(v: string) => !!v || 'Meno musí byt vyplené'];
	email = '';
	emailRules = [
		(v: string) => !!v || 'E-mail musí byt vyplnený',
		(v: string) => /.+@.+/.test(v) || 'E-mail nie je validný'
	];
	text = '';
	textRules = [(v: string) => !!v || 'Správa musí obsahovat text'];
	reasons = ['Objednávka kurzu *', 'Záujem o líčenie', 'Líčenie na svadbu', 'Iné'];
	selected = '';

	clear() {
		(this.$refs.form as Vue & { reset: () => boolean }).reset();
	}

	async sendMail() {
		(this.$refs.form as Vue & { validate: () => boolean }).validate();
		if (this.valid && this.firstname != '' && this.email != '' && this.text != '') {
			this.isLoading = true;
			const body: SendMailRequest = {
				link: this.webInfo.link,
				recipient: this.textByKey('mail')?.value ?? this.webInfo.mail,
				name: this.firstname,
				email: this.email,
				body: `${this.text} \n \n Správa od ${this.firstname} \n ${this.email}`,
				subject:
					(this.selected ?? this.reasons[1]) + ` (správa z webu ${this.webInfo.link})`
			};
			await ContactsService.sendMail(body)
				.then(ans => {
					if (ans.data.data.sendMail) {
						this.isLoading = false;
						(this.$refs.form as Vue & { reset: () => boolean }).reset();
						this.$toast.success('Správa odoslaná');
						this.clear();
					} else {
						this.$toast.error(
							'Nastala chyba skúste to znovu alebo využite iný spôsob kontaktu.'
						);
						this.isLoading = false;
					}
				})
				.catch(() => {
					this.isLoading = false;
				});
		}
	}
}
