


























































































































































import { moduleTypes } from '../store/types';
import {
	getterTypes as homeGettersTypes,
	HomeState,
	actionTypes as homeActionsTypes
} from '@/store/modules/home/types';
import { getterTypes as webInfoGettersTypes, WebInfoState } from '@/store/modules/webInfo/types';
import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import DocumentsService from '@/services/documentsService';
import TextEditor from '@/components/TextEditor.vue';
import FileReplacer from '@/components/file/FileReplacer.vue';
import { PhotoFile, TextData, Album } from '@/services';
import moment from 'moment';
import AlbumService from '@/services/albumService';
import TextService from '@/services/textService';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
const homeNamespace = namespace(moduleTypes.HOME);
const webInfoNamespace = namespace(moduleTypes.WEB_INFO);

@Component({
	components: { TextEditor, FileReplacer }
})
export default class Home extends Vue {
	@homeNamespace.Getter(homeGettersTypes.HOME_DATA) homeData: HomeState;
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;
	@webInfoNamespace.Getter(webInfoGettersTypes.WEB_INFO)
	webInfo: WebInfoState;
	@homeNamespace.Action(homeActionsTypes.LOAD_TEXTS) loadTexts: () => Promise<void>;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	albums: Album[] = [];

	get photos() {
		return this.albums
			.find(a => a._id === this.textField('selectedAlbum')?.value)
			?.photos.map(d => {
				return {
					src: d.link,
					thumbnail: d.link,
					w: d.width,
					h: d.height
				};
			});
	}

	async selectAlbum(item: any) {
		// console.log(item);
		const text = this.textField('selectedAlbum');
		if (text) {
			text.value = this.albums.find(a => a.title === item)?._id ?? text.value;
			await TextService.updateTextById(text._id, text).then(async res => {
				await this.loadTexts();
			});
		}
	}

	textField(key: string) {
		return this.textByKey(key);
	}

	async loadPhotos(albumId: string) {
		const data: PhotoFile[] = [];
		await DocumentsService.getPhotosWithLimit(albumId, 4).then(response => {
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			data.sort(
				(a, b) =>
					moment(b.metadata.trackingInfo.modified)
						.toDate()
						.getTime() -
					moment(a.metadata.trackingInfo.modified)
						.toDate()
						.getTime()
			).map(d => {
				return {
					src: d.link,
					thumbnail: d.link,
					w: d.width,
					h: d.height
				};
			});
		});
		return data;
	}

	async getAlbums() {
		this.albums = [];
		await AlbumService.getAlbums().then(async response => {
			const data: Album[] = [];
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			data.map(async d => {
				const photos = await this.loadPhotos(d._id);
				d.photos = photos ?? [];
				this.albums.push(d);
			});
			return data;
		});
	}

	async mounted() {
		// this.loadPhotos();
		await this.loadTexts();
		await this.getAlbums();
	}
}
