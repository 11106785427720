export interface RootState {
	version: string;
}

export const moduleTypes = {
	LAYOUT: 'layout',
	LOGIN: 'login',
	WEB_INFO: 'webInfo',
	HOME: 'home'
};

export const actionTypes = {};

export const mutationTypes = {};

export const getterTypes = {
	version: 'version'
};
