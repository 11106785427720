/* eslint-disable @typescript-eslint/no-explicit-any */

import store from '@/store';
import {
	getterTypes as layoutGetterTypes,
	mutationTypes as layoutMutationTypes
} from '@/store/modules/layout/types';
import { moduleTypes } from '@/store/types';
import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Home from '@/views/Home.vue';
import Galeria from '@/views/Galeria.vue';
import GaleriaDetail from '@/views/GaleriaDetail.vue';
import Cennik from '@/views/Cennik.vue';
import Spoluprace from '@/views/Spoluprace.vue';
import Kurzy from '@/views/Kurzy.vue';
import Objednavky from '@/views/Objednavky.vue';
import { routerTypes } from './router-types';
import { layoutTypes } from '@/services/types/layoutTypes';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: routerTypes.O_MNE,
		component: Home,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/galeria',
		name: routerTypes.GALERIA,
		component: Galeria,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/galeria/:albumId',
		name: routerTypes.GALERIA_DETAIL,
		component: GaleriaDetail,
		props: (route: Route) => ({
			albumId: route.params.albumId
		}),
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/cennik',
		name: routerTypes.CENNIK,
		component: Cennik,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/spoluprace',
		name: routerTypes.SPOLUPRACE,
		component: Spoluprace,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/kurzy',
		name: routerTypes.KURZY,
		component: Kurzy,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/objednavky',
		name: routerTypes.OBJEDNAVKY,
		component: Objednavky,
		meta: {
			layout: layoutTypes.DEFAULT
		}
	},
	{
		path: '/*',
		name: routerTypes.NOT_FOUND,
		component: () => import('../views/NotFound.vue'),
		meta: {
			layout: layoutTypes.EMPTY
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	routes
});

// resolve Layout
router.beforeEach((to: any, from: any, next: () => void) => {
	if (
		typeof to.meta.layout !== 'undefined' &&
		store.getters[moduleTypes.LAYOUT + '/' + layoutGetterTypes.layout] !== to.meta.layout
	) {
		store.commit(moduleTypes.LAYOUT + '/' + layoutMutationTypes.SET_LAYOUT, to.meta.layout);
	}
	next();
});

export default router;
