





































































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';

import {
	getterTypes as webInfoGetterTypes,
	WebInfoState
} from '../../../store/modules/webInfo/types';
import { moduleTypes } from '../../../store/types';
import { namespace } from 'vuex-class';
import FileReplacer from '@/components/file/FileReplacer.vue';
import { getterTypes as homeGettersTypes } from '@/store/modules/home/types';
import { TextData } from '@/services';

const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
const homeNamespace = namespace(moduleTypes.HOME);

@Component({
	components: { FileReplacer }
})
export default class TheAppBar extends Vue {
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;
	@homeNamespace.Getter(homeGettersTypes.TEXT_BY_KEY) textByKey: (key: string) => TextData | null;

	get titleheight() {
		if (this.$vuetify.breakpoint.mdAndUp) return '150px';
		return undefined;
	}

	textField(key: string) {
		return this.textByKey(key);
	}

	menus = [
		{ title: 'O mne', to: '/' },
		{ title: 'Galéria', to: '/galeria' },
		{ title: 'Cenník', to: '/cennik' },
		// { title: 'Spolupráce', to: '/spoluprace' },
		{ title: 'Kurzy', to: '/kurzy' },
		{ title: 'Objednávkový formulár', to: '/objednavky' }
	];
}
