import FirebaseService from './firebaseService';
import { Album } from './types';

export enum TablesNamesAlbum {
	ALBUM = 'album'
}

export default class AlbumService extends FirebaseService {
	/////////////////////////////////////////
	//                Albums               //
	/////////////////////////////////////////

	static getAlbums() {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesAlbum.ALBUM}`
		);
	}

	static getAlbumById(id: string) {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesAlbum.ALBUM}/${id}`
		);
	}

	static setAlbum(data: Album) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesAlbum.ALBUM}/${data._id}`,
			data
		);
	}
	static deleteAlbum(albumId: string) {
		return this.removeData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesAlbum.ALBUM}/${albumId}`
		);
	}
	static updateAlbumById(id: string, data: Partial<Album>) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesAlbum.ALBUM}/${id}`,
			data
		);
	}
}
