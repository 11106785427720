import { MutationTree } from 'vuex';
import { LoginState, mutationTypes } from './types';
import { User, Roles } from '@/services';

export const mutations: MutationTree<LoginState> = {
	[mutationTypes.SET_LOGIN](state, payload: boolean) {
		state.isLogged = payload;
	},
	[mutationTypes.SET_RESET_PASSWORD](state, payload: boolean) {
		state.resetPassword = payload;
	},
	[mutationTypes.SET_USER_INFO](state, payload: User | undefined) {
		if (payload === undefined) {
			state.email = '';
			state.isAdmin = false;
			state.isEditor = false;
			state.isPostman = false;
			state.isBasic = false;
			state.lastLogin = '';
			state.resetPassword = false;
			state.isActive = true;
		} else {
			state.email = payload.email;
			state.isAdmin = payload.role === Roles.ADMIN;
			state.isEditor = payload.role === Roles.EDITOR;
			state.isPostman = payload.role === Roles.POSTMAN;
			state.isBasic = payload.role === Roles.BASIC;
			state.isActive = payload.active !== undefined ? payload.active : true;
			state.lastLogin = payload.lastLogin ?? '';
			state.resetPassword = payload.passwordReset || false;
		}
	},
	[mutationTypes.SET_NUMBER_OF_INCORRECT_LOGINS](state, payload: number) {
		state.numberOfIncorrectLogins = payload;
	}
};
