




































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { namespace, Getter } from 'vuex-class';
import { moduleTypes, getterTypes } from '../../../store/types';
import {
	getterTypes as webInfoGetterTypes,
	WebInfoState
} from '../../../store/modules/webInfo/types';
const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
import LoginForm from '@/components/Login/LoginForm.vue';
import LoginResetForm from '@/components/Login/LoginResetForm.vue';
import LoginBlock from '@/components/Login/LoginBlock.vue';
const loginNamespace = namespace(moduleTypes.LOGIN);
import {
	getterTypes as loginGetterTypes,
	actionTypes as loginActionTypes
} from '@/store/modules/login/types';
import { User } from '@sentry/browser';

@Component({
	components: { LoginForm, LoginResetForm, LoginBlock }
})
export default class TheFooter extends Vue {
	@Getter(getterTypes.version) version: string;
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;

	@loginNamespace.Action(loginActionTypes.LOGOUT) logout: any;
	@loginNamespace.Getter(loginGetterTypes.IS_EDITOR) isEditor: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_ADMIN) isAdmin: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_ACTIVE) isActive: boolean;
	@loginNamespace.Getter(loginGetterTypes.USER_INFO) userInfo: User;
	@loginNamespace.Getter(loginGetterTypes.RESET_PASSWORD) resetPassword: boolean;
}
