import FirebaseService from './firebaseService';
import { AxiosPromise, AxiosResponse } from 'axios';
import { FileUploadResponse, PhotoFile } from './types';

export enum TablesNamesDocuments {
	PHOTOS = 'photos'
}

export default class DocumentsService extends FirebaseService {
	/////////////////////////////////////////
	//                Files                //
	/////////////////////////////////////////

	static uploadFile(file: FormData): AxiosPromise<AxiosResponse<FileUploadResponse>> {
		return this.post('file-upload', undefined, file, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	static deleteFile(filePath: string) {
		return this.post('file-delete', { file: filePath });
	}

	/////////////////////////////////////////
	//             Photo Files             //
	/////////////////////////////////////////

	static getPhotos(albumId: string) {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesDocuments.PHOTOS}/${albumId}`
		);
	}

	static getPhotosWithLimit(albumId: string, limit?: number) {
		return this.getDataWithLimit(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesDocuments.PHOTOS}/${albumId}`,
			limit ?? 7
		);
	}

	static setPhoto(data: PhotoFile) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesDocuments.PHOTOS}/${data.albumId}/${data._id}`,
			data
		);
	}
	static deletePhoto(albumId: string, id: string) {
		return this.removeData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesDocuments.PHOTOS}/${albumId}/${id}`
		);
	}
	static updatePhotoById(id: string, data: Partial<PhotoFile>) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesDocuments.PHOTOS}/${data.albumId}/${id}`,
			data
		);
	}
}
