import { User } from '@/services';

export interface LoginState {
	isLogged: boolean;
	resetPassword: boolean;
	isAdmin: boolean;
	isEditor: boolean;
	isPostman: boolean;
	isBasic: boolean;
	isActive: boolean;
	email: string;
	lastLogin: string;
	numberOfIncorrectLogins: number;
}

export const actionTypes = {
	TOGGLE_LOGIN: 'toggleLogin',
	LOGOUT: 'logout',
	RESET_PASSWORD: 'resetPassword',
	CHANGE_NUMBER_OF_INCORRECT_LOGINS: 'changeNumberOfIncorrectLogins'
};

export const mutationTypes = {
	SET_LOGIN: 'setLogin',
	SET_USER_INFO: 'setUserInfo',
	SET_RESET_PASSWORD: 'setResetPassword',
	SET_NUMBER_OF_INCORRECT_LOGINS: 'setNumberOfIncorrectLogins'
};

export const getterTypes = {
	IS_EDITOR: 'isEditor',
	IS_ADMIN: 'isAdmin',
	IS_POSTMAN: 'isPostman',
	IS_LOGGED: 'isLogged',
	IS_ACTIVE: 'isActive',
	USER_INFO: 'userInfo',
	RESET_PASSWORD: 'resetPassword',
	NUMBER_OF_INCORRECT_LOGINS: 'numberOfIncorrectLogins'
};
