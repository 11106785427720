










































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';

import { moduleTypes } from '../../store/types';
const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
import { getterTypes as webInfoGetterTypes, WebInfoState } from '../../store/modules/webInfo/types';
const loginNamespace = namespace(moduleTypes.LOGIN);
import { actionTypes as loginActionTypes } from '@/store/modules/login/types';

@Component
export default class LoginBlock extends Vue {
	@Prop({ default: false }) showDialog: boolean;
	@loginNamespace.Action(loginActionTypes.LOGOUT) logout: any;
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;

	valid = true;
	dialog = false;
	logoutLoading = false;

	mounted() {
		if (this.showDialog) {
			this.dialog = true;
		}
	}

	async close() {
		this.logoutLoading = true;
		await this.logout().then(() => {
			this.logoutLoading = false;
			this.dialog = false;
		});
	}
}
