import FirebaseService from './firebaseService';
import { BaseEntity, createGuid, Metadata, TrackingInfo } from './types';

export enum TablesNamesCennik {
	CATEGORY = 'category'
}

export class CategoryParam {
	text: string;
	value: string;
	constructor(data?: Partial<CategoryParam>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
}

export class CennikCategory extends BaseEntity {
	title: string;
	params?: CategoryParam[];

	constructor(data?: Partial<CennikCategory>) {
		super();
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}

	static create(data: Partial<CennikCategory>): CennikCategory {
		return new CennikCategory({
			_id: data._id ?? createGuid(),
			metadata:
				data.metadata ||
				new Metadata({
					type: CennikCategory.name,
					trackingInfo: new TrackingInfo({ created: new Date().toISOString() })
				}),
			title: data.title,
			params: data.params
		});
	}
}

export default class CennikService extends FirebaseService {
	/////////////////////////////////////////
	//                Albums               //
	/////////////////////////////////////////

	static getCategories() {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesCennik.CATEGORY}`
		);
	}

	static getCategoryById(id: string) {
		return this.getData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesCennik.CATEGORY}/${id}`
		);
	}

	static setCategory(data: CennikCategory) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesCennik.CATEGORY}/${data._id}`,
			data
		);
	}
	static deleteCategory(textId: string) {
		return this.removeData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesCennik.CATEGORY}/${textId}`
		);
	}
	static updateCategoryById(id: string, data: Partial<CennikCategory>) {
		return this.setData(
			(process.env.VUE_APP_DB !== undefined ? process.env.VUE_APP_DB + '/' : '') +
				`${TablesNamesCennik.CATEGORY}/${id}`,
			data
		);
	}
}
