import { MutationTree } from 'vuex';
import { LayoutState, mutationTypes } from '@/store/modules/layout/types';

export const mutations: MutationTree<LayoutState> = {
	[mutationTypes.SET_LAYOUT](state, payload: string) {
		state.layout = payload;
	},

	[mutationTypes.SET_LOADING](state, payload: boolean) {
		state.loading = payload;
	}
};
