import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import i18n from '@/plugins/i18n';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@fortawesome/fontawesome-free/css/all.css';
import * as firebase from 'firebase/app';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import VueFileAgent from 'vue-file-agent';
import VuePictureSwipe from 'vue-picture-swipe';

Vue.component('vue-picture-swipe', VuePictureSwipe);
Vue.use(Toast);
Vue.use(VueFileAgent);

Vue.config.productionTip = false;

Vue.prototype.$moment = moment;
moment.locale(i18n.locale);

const firebaseConfig = {
	apiKey: 'AIzaSyBvuqsfEhog756GO6Nr074isEG-4PHpuxY',
	authDomain: 'annomissart-2dfee.firebaseapp.com',
	projectId: 'annomissart-2dfee',
	storageBucket: 'annomissart-2dfee.appspot.com',
	messagingSenderId: '957288542924',
	appId: '1:957288542924:web:b22801207f1c60ae9d0869',
	measurementId: 'G-LNTB1YTN15',
	databaseURL: 'https://annomissart-2dfee-default-rtdb.europe-west1.firebasedatabase.app/'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App)
}).$mount('#app');
