// const GuidTypescript1 = require('guid-typescript');

import { Guid } from 'guid-typescript';

export function createGuid() {
	return Guid.create().toString();
}

export class BaseEntity {
	_id: string;
	metadata: Metadata;

	constructor(data?: Partial<TrackingInfo>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
}

export class TrackingInfo {
	initiator: string;
	created: string;
	creator: string;
	modified: string;
	version: number;
	constructor(data?: Partial<TrackingInfo>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}
}

export class Metadata {
	trackingInfo: TrackingInfo;
	type: string;
	valid: boolean;

	constructor(data?: Partial<Metadata>) {
		if (data !== null && typeof data === 'object') {
			Object.assign(this, data);
		}
	}

	static create(data: Partial<Metadata>): Metadata {
		return new Metadata({
			valid: data.valid || true,
			type: data.type || 'unknow type',
			trackingInfo: {
				created: new Date().toISOString(),
				creator: data.trackingInfo?.creator || 'uknow creator',
				initiator: data.trackingInfo?.initiator || 'unknow',
				modified: new Date().toISOString(),
				version: 0
			}
		});
	}
}
