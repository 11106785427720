var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"main-tab",staticStyle:{"z-index":"50"},attrs:{"app":"","fixed":"","dark":"","color":"secondary","shrink-on-scroll":_vm.$vuetify.breakpoint.mdAndUp,"extended":true,"src":_vm.textField('mainPhoto').value}},[_c('file-replacer',{key:"mainPhoto",attrs:{"fileWay":_vm.textField('mainPhoto')}}),_c('v-toolbar-title',{staticClass:"overflow-visible ",staticStyle:{"width":"100%"}},[_c('v-card',{staticClass:"d-flex flex-column overflow-visible",attrs:{"height":_vm.titleheight,"width":"100%","color":"transparent","flat":""}},[_c('v-card-title',{staticClass:"justify-center d-flex pt-14 pt-md-5"},[_c('v-card',{attrs:{"to":"/","color":"transparent","elevation":"0"}},[_c('v-sheet',{class:{
							'white--text': _vm.$vuetify.breakpoint.sm,
							'display-1': true,
							'd-flex': true
						},attrs:{"color":"transparent"}},[_c('div',{staticStyle:{"display":"inline","width":"100px"}},[_c('v-img',{attrs:{"src":require('@/assets/imgs/white.png'),"height":"100%","contain":""}})],1),_c('div',{staticClass:"ml-4 pa-2 text-h6 text-bold text-sm-h3 text-break text-right",staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(_vm.webInfo.name)+" ")])])],1)],1),_c('v-spacer',{staticClass:"hidden-md-and-down"}),_c('v-card-actions',{staticClass:"justify-center mt-5 overflow-visible hidden-md-and-down"},[_c('v-sheet',{staticClass:"d-flex justify-center not-rounded",staticStyle:{"z-index":"10"},attrs:{"min-width":"60%","color":"secondary"}},_vm._l((_vm.menus),function(item,index){return _c('v-btn',{key:index,staticClass:"py-6 px-5 title title-font not-rounded",attrs:{"large":"","text":"","to":item.to}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)],1)],1),_c('v-spacer'),_c('v-sheet',{staticClass:"hidden-md-and-up",staticStyle:{"position":"absolute","top":"105px","right":"-2px","z-index":"10000"},attrs:{"color":"transparent"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-5 my-0",attrs:{"color":"white","dark":"","max-width":"20","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"secondary"}},[_vm._v("fa-bars")])],1)]}}])},[_c('v-list',_vm._l((_vm.menus),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_c('v-btn',{attrs:{"block":"","depressed":"","to":item.to}},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }