











































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { moduleTypes } from '@/store/types';

const loginNamespace = namespace(moduleTypes.LOGIN);
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
import DocumentsService from '../../services/documentsService';
import { FileRecord } from 'vue-file-agent';
import { FileUploadResponse, PhotoFile, Metadata, TrackingInfo, User, Album } from '../../services';
import moment from 'moment';
import { POSITION } from 'vue-toastification';
import { RawFileRecord } from 'vue-file-agent/types/src/lib/file-record';
import prettyBytes from 'pretty-bytes';
import { Prop } from 'vue-property-decorator';
import AlbumService from '@/services/albumService';

@Component({
	components: {}
})
export default class FileUploader extends Vue {
	@Prop({ required: true }) albumId: string;
	// @loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;
	@loginNamespace.Getter(loginGetterTypes.USER_INFO) userInfo: User;
	// @loginNamespace.Getter(loginGetterTypes.RESET_PASSWORD) resetPassword: boolean;

	fileRecords: FileRecord[] = [];
	uploadUrl = 'api/file-upload';
	uploadHeaders = { 'X-Test-Header': 'vue-file-agent' };
	fileRecordsForUpload: FileRecord[] = [];

	photos: PhotoFile[] = [];

	fileuploading = false;
	fileDeleting = false;

	album: Album | null = null;

	get totalSize(): number {
		return this.fileRecordsForUpload.map(f => +f.size).reduce((sum, x) => (sum += x), 0);
	}

	get uploadedSize() {
		return this.fileRecordsForUpload
			.map(f => {
				return { progress: f.progress() || 0, size: +f.size };
			})
			.reduce((sum, x) => (sum += (x.size * x.progress) / 100), 0);
	}

	get additionalInfo() {
		const progress = (this.uploadedSize / this.totalSize) * 100;
		return (
			prettyBytes(this.uploadedSize) +
			' / ' +
			prettyBytes(this.totalSize) +
			' (' +
			Math.round(isNaN(progress) ? 0 : progress) +
			'%)'
		);
	}

	async mounted() {
		this.loadPhotos();
		this.album = await AlbumService.getAlbumById(this.albumId);
	}

	async loadPhotos() {
		await DocumentsService.getPhotos(this.albumId).then(response => {
			const data: PhotoFile[] = [];
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			this.photos = data.sort(
				(a, b) =>
					moment(b.metadata.trackingInfo.created)
						.toDate()
						.getTime() -
					moment(a.metadata.trackingInfo.created)
						.toDate()
						.getTime()
			);
		});
	}

	async uploadFiles() {
		this.fileuploading = true;

		(this.$refs.vueFileAgent as Vue & {
			upload(
				url: string,
				headers: object,
				fileRecordsOrRaw: FileRecord[],
				createFormData?: ((fileRecord: FileRecord) => FormData) | undefined
			): Promise<any>;
		})
			.upload('/api/file-upload/index.php', {}, this.fileRecordsForUpload, fileData => {
				const formData = new FormData();
				formData.append('file', fileData.file);
				formData.append('way', `../../data/photos/${this.albumId}/`);
				return formData;
			})
			.then(async res => {
				for (let i = 0; i < res.length; i++) {
					if (res[i].data?.data?.fileUpload === true) {
						const index = this.photos
							.map(a => a.title)
							.indexOf(this.fileRecordsForUpload[i].file.name);
						if (index === -1) {
							await DocumentsService.setPhoto(
								PhotoFile.create({
									link: `../../data/photos/${this.albumId}/${this.fileRecordsForUpload[i].file.name}`,
									title: this.fileRecordsForUpload[i].file.name,
									width: this.fileRecordsForUpload[i].dimensions.width,
									height: this.fileRecordsForUpload[i].dimensions.height,
									size: (this.fileRecordsForUpload[i] as FileRecord &
										RawFileRecord).sizeText,
									albumId: this.albumId,
									metadata: Metadata.create({
										type: PhotoFile.name,
										trackingInfo: {
											created: new Date().toISOString(),
											modified: new Date().toISOString(),
											version: 1,
											initiator: this.userInfo.email || 'unknow user',
											creator: this.userInfo.email || 'unknow user'
										}
									})
								})
							)
								.then(async () => {
									await this.loadPhotos();
								})
								.catch(err => {
									this.$toast.error('Create failed', {
										timeout: 5000
									});
									err;
								});
							this.fileuploading = false;
							this.$toast.success(
								`Súbor ${this.fileRecordsForUpload[i].file.name} nahraný`,
								{
									position: POSITION.BOTTOM_LEFT,
									timeout: 3500
								}
							);
						} else {
							const photo = this.photos[index];
							photo.metadata.trackingInfo = Object.assign(
								photo.metadata.trackingInfo ||
									new TrackingInfo({
										created: new Date().toISOString(),
										creator: this.userInfo.email
									}),
								{
									modified: new Date().toISOString(),
									initiator: this.userInfo.email,
									version: photo.metadata.trackingInfo?.version
										? photo.metadata.trackingInfo.version + 1
										: 1
								}
							);
							await DocumentsService.updatePhotoById(photo._id, photo)
								.then(() => this.loadPhotos())
								.catch(err => {
									this.$toast.error('Update failed', {
										timeout: 5000
									});
									err;
								});
							this.fileuploading = false;
							this.$toast.warning(
								`Súbor ${this.fileRecordsForUpload[i].file.name} nahradený`,
								{
									position: POSITION.BOTTOM_LEFT,
									timeout: 3500
								}
							);
						}
						// this.fileRecords = [];
					} else {
						this.$toast.error(
							`Súbor ${this.fileRecordsForUpload[i].file.name} sa nepodarilo nahrať!`,
							{
								timeout: 5000
							}
						);
					}
				}
				if (this.album)
					await this.loadPhotos().then(async () => {
						await AlbumService.updateAlbumById(
							this.albumId,
							Object.assign(this.album, {
								numberOfPhotos: this.photos.length
							} as Album)
						);
						this.album = await AlbumService.getAlbumById(this.albumId);
					});
				this.fileRecordsForUpload = [];
				this.fileuploading = false;
			})
			.catch(err => {
				this.fileuploading = false;
				const error = err as {
					data: FileUploadResponse;
					error: Error;
				};
				let errorMsg = 'error.file.not-save';
				if (error.data) {
					if (error.data.fileInfo.size) errorMsg = 'error.file.size';
					if (error.data.fileInfo.extension) errorMsg = 'error.file.extension';
					if (error.data.fileInfo.type) errorMsg = 'error.file.type';
				}
				this.$toast.error(errorMsg, {
					timeout: 5000
				});
			});
	}
	filesSelected(fileRecordsNewlySelected: FileRecord[]) {
		const validFileRecords = fileRecordsNewlySelected.filter(fileRecord => !fileRecord.error);
		this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
	}

	onBeforeDelete(fileRecord: FileRecord) {
		const i = this.fileRecordsForUpload.indexOf(fileRecord);
		if (i !== -1) this.fileRecordsForUpload.splice(i, 1);
		const k = this.fileRecords.indexOf(fileRecord);
		if (k !== -1) this.fileRecords.splice(k, 1);
	}

	async deleteFileItem(id: string) {
		this.fileDeleting = true;
		const photo = this.photos.find(a => a._id === id);
		if (photo) {
			await DocumentsService.deleteFile(photo.link).then(() => this.removePhotoFromDB(id));
		}
		if (this.album)
			await AlbumService.updateAlbumById(
				this.albumId,
				Object.assign(this.album, { numberOfPhotos: this.photos.length } as Album)
			);
		this.album = await AlbumService.getAlbumById(this.albumId);
	}

	async removePhotoFromDB(id: string) {
		await DocumentsService.deletePhoto(this.albumId, id)
			.then(() => {
				this.loadPhotos();
				this.$toast.success('Súbor odstránený', {
					position: POSITION.BOTTOM_RIGHT,
					timeout: 2000
				});
			})
			.catch(() => {
				this.$toast.error('Nastala chyba!', {
					timeout: 5000
				});
			});
		this.fileDeleting = false;
	}
}
