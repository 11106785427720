import FirebaseService from './firebaseService';
import { User } from './types';

export enum TablesNamesUser {
	USERS = 'users'
}

export default class UsersService extends FirebaseService {
	static loginFirebase(email: string, password: string) {
		return this.login(email, password);
	}

	static logoutFirebase() {
		return this.logout();
	}

	static passwordUpdateFirebase(password: string) {
		return this.passwordUpdate(password);
	}

	static passwordReset(email: string) {
		return this.passwordResetMail(email);
	}

	static getUserInfo(id: string): Promise<User> {
		return this.getData(`${TablesNamesUser.USERS}/${id}`);
	}

	static getActualUser() {
		return this.actualUser();
	}

	static getUsers(): Promise<any> {
		return this.getData(`${TablesNamesUser.USERS}`);
	}

	static createUser(id: string, user: User) {
		return this.setData(`${TablesNamesUser.USERS}/${id}`, user);
	}

	static updateUserById(id: string, user: Partial<User>) {
		return this.setData(`${TablesNamesUser.USERS}/${id}`, user);
	}

	static removeUserById(id: string) {
		return this.removeData(`${TablesNamesUser.USERS}/${id}`);
	}
}
