import { getterTypes } from '@/store/modules/login/types';
import { RootState } from '@/store/types';
import { GetterTree } from 'vuex';
import { LoginState } from './types';
import { Roles, User } from '@/services';

export const getters: GetterTree<LoginState, RootState> = {
	[getterTypes.IS_EDITOR](state): boolean {
		return state.isActive && (state.isAdmin || state.isEditor || false);
	},
	[getterTypes.IS_ADMIN](state): boolean {
		return state.isActive && (state.isAdmin || false);
	},
	[getterTypes.IS_POSTMAN](state): boolean {
		return state.isActive && (state.isAdmin || state.isEditor || state.isPostman || false);
	},
	[getterTypes.IS_LOGGED](state): boolean {
		return state.isLogged;
	},
	[getterTypes.IS_ACTIVE](state): boolean {
		return state.isActive;
	},
	[getterTypes.USER_INFO](state): User | undefined {
		return User.from(
			Object.assign(state, {
				role: state.isAdmin
					? Roles.ADMIN
					: state.isEditor
					? Roles.EDITOR
					: state.isPostman
					? Roles.POSTMAN
					: Roles.BASIC
			} as User)
		);
	},
	[getterTypes.RESET_PASSWORD](state): boolean {
		return state.resetPassword;
	},
	[getterTypes.NUMBER_OF_INCORRECT_LOGINS](state): number {
		return state.numberOfIncorrectLogins;
	}
};
