import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState, getterTypes } from './types';
import { layout } from '@/store/modules/layout';
import { login } from '@/store/modules/login';
import { webInfo } from '@/store/modules/webInfo';
import { home } from '@/store/modules/home';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

let version = 'unknown';
try {
	version = require('../../package.json').version;
} catch (error) {
	// console.error(error);
}

const vuexLocal = new VuexPersistence<RootState>({
	storage: window.localStorage
});

export const store: StoreOptions<RootState> = {
	state: {
		version: `v${version}`
	},
	getters: {
		[getterTypes.version](state): string {
			return state.version;
		}
	},
	mutations: {},
	actions: {},
	modules: {
		layout,
		login,
		webInfo,
		home
	},
	plugins: [vuexLocal.plugin]
};

export default new Vuex.Store<RootState>(store);
