




























































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import FileUploader from '@/components/file/FileUploader.vue';
import DocumentsService from '@/services/documentsService';
import { PhotoFile, Album } from '@/services';
import moment from 'moment';
import AlbumService from '@/services/albumService';
import { POSITION } from 'vue-toastification';
import { moduleTypes } from '@/store/types';
import { namespace } from 'vuex-class';
import { getterTypes as loginGetterTypes } from '@/store/modules/login/types';
const loginNamespace = namespace(moduleTypes.LOGIN);

@Component({
	components: { FileUploader }
})
export default class GaleriaDetail extends Vue {
	@Prop({ required: true }) albumId: string;
	@loginNamespace.Getter(loginGetterTypes.IS_LOGGED) isLogged: boolean;

	dialog = false;
	dialogEdit = false;
	photos: {
		src: string;
		thumbnail: string;
		w: number;
		h: number;
	}[] = [];
	rawPhotos: PhotoFile[] = [];

	fab = false;

	album: Album | null = null;
	newAlbumName = '';

	@Watch('dialog')
	onDialogChange() {
		if (this.dialog === false) this.loadPhotos();
	}

	// eslint-disable-next-line
	onScroll(e: any) {
		if (typeof window === 'undefined') return;
		const top = window.pageYOffset || e.target.scrollTop || 0;
		this.fab = top > 20;
	}
	toTop() {
		this.$vuetify.goTo(0);
	}

	async getAlbum() {
		await AlbumService.getAlbumById(this.albumId).then(response => {
			this.album = Album.create({
				_id: response._id,
				title: response.title,
				numberOfPhotos: response.numberOfPhotos,
				metadata: response.metadata
			});
		});
	}

	async editAlbum() {
		if (!this.album) return;

		await AlbumService.updateAlbumById(this.albumId, this.album).then(async () => {
			await this.getAlbum();
			this.dialogEdit = false;
		});
	}

	async deleteAlbum() {
		this.rawPhotos.map(async photo => {
			if (photo) {
				await DocumentsService.deleteFile(photo.link).then(() =>
					this.removePhotoFromDB(photo._id)
				);
			}
		});
		await AlbumService.deleteAlbum(this.albumId);
		this.$router.push('/galeria');
	}

	async removePhotoFromDB(id: string) {
		await DocumentsService.deletePhoto(this.albumId, id)
			.then(() => {
				this.loadPhotos();
				this.$toast.success('Súbor odstránený', {
					position: POSITION.BOTTOM_RIGHT,
					timeout: 2000
				});
			})
			.catch(() => {
				this.$toast.error('Nastala chyba!', {
					timeout: 5000
				});
			});
	}

	async closeEdit() {
		this.dialogEdit = false;
		await this.getAlbum();
	}

	async loadPhotos() {
		await DocumentsService.getPhotos(this.albumId).then(response => {
			// console.log(response);
			const data: PhotoFile[] = [];
			if (response) {
				Object.keys(response).forEach(key => {
					data.push(response[key]);
				});
			}
			this.rawPhotos = data;
			this.photos = data
				.sort(
					(a, b) =>
						moment(b.metadata.trackingInfo.modified)
							.toDate()
							.getTime() -
						moment(a.metadata.trackingInfo.modified)
							.toDate()
							.getTime()
				)
				.map(d => {
					return {
						src: d.link,
						thumbnail: d.link,
						w: d.width,
						h: d.height
					};
				});
		});
	}

	async mounted() {
		this.loadPhotos();
		await this.getAlbum();
	}
}
