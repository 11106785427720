













































































import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Vue from 'vue';
import UserService from '../../services/usersService';
import { TranslateResult } from 'vue-i18n';
import { moduleTypes } from '../../store/types';

const webInfoNamespace = namespace(moduleTypes.WEB_INFO);
import { getterTypes as webInfoGetterTypes, WebInfoState } from '../../store/modules/webInfo/types';

const loginNamespace = namespace(moduleTypes.LOGIN);
import {
	getterTypes as loginGetterTypes,
	actionTypes as loginActionTypes
} from '@/store/modules/login/types';

@Component
export default class LoginResetForm extends Vue {
	@Prop({ default: false }) showLogin: boolean;
	@loginNamespace.Action(loginActionTypes.RESET_PASSWORD) resetPassword: any;
	@loginNamespace.Action(loginActionTypes.LOGOUT) logout: any;
	@webInfoNamespace.Getter(webInfoGetterTypes.WEB_INFO) webInfo: WebInfoState;

	valid = true;
	dialog = false;

	loginLoader = false;
	password = '';
	password2 = '';
	showhint = false;
	hint = '';
	rules = [(v: string) => !!v || 'Toto pole je povinné'];
	errorMsg = '';
	mounted() {
		if (this.showLogin) {
			this.dialog = true;
		}
	}

	loginReset() {
		if ((this.$refs.loginResetForm as Vue & { validate: () => boolean }).validate()) {
			this.loginLoader = true;
			this.showhint = false;
			this.hint = '';
			if (this.password === this.password2) {
				UserService.passwordUpdateFirebase(this.password)
					.then(() => {
						this.resetPassword();
						this.loginLoader = false;
						this.password = '';
						this.password2 = '';
						this.dialog = false;
						window.scrollTo(0, 0);
						this.$emit('loginResetSuccessful');
						this.$toast.success('Zmena hesla úspešná!', {
							timeout: 3000
						});
					})
					.catch(async error => {
						this.loginLoader = false;
						// console.log(error.code);
						this.errorMsg = error.code;
						if (error.code === 'auth/requires-recent-login') await this.logout();
					});
			} else {
				this.loginLoader = false;
				this.showhint = true;
				this.hint = 'Heslá sa nezhodujú';
			}
		}
	}
}
