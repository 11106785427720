var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"galeria mb-12",staticStyle:{"min-height":"700px"}},[_c('h1',[_vm._v("Galeria")]),_c('v-divider',{staticClass:"mb-8"}),(_vm.isLogged)?_c('v-dialog',{attrs:{"max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"mb-4 ml-4",attrs:{"color":"primary","dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-3",attrs:{"small":""}},[_vm._v("fa-plus")]),_vm._v("Pridať album ")],1)]}}],null,false,792566749),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"secondary"},[_c('v-card-title',[_vm._v("Pridať nový album")]),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"label":"Názov albumu","color":"#d4af34","required":"","dark":""},model:{value:(_vm.albumTitle),callback:function ($$v) {_vm.albumTitle=$$v},expression:"albumTitle"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"error",attrs:{"small":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Zavrieť")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":_vm.addAlbum}},[_vm._v("Pridať album")])],1)],1)],1):_vm._e(),(_vm.albums)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.albums),function(album){return _c('v-col',{key:album._id,staticClass:"pa-4",attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"to":'/galeria/' + album._id,"height":"100%","color":"secondary"}},[_c('v-row',{attrs:{"no-gutters":""}},[_vm._l((3),function(n){return _c('v-col',{key:n,staticClass:"ma-0 pa-0",attrs:{"cols":"6"}},[(
								album.photos && n <= album.numberOfPhotos && album.photos[n - 1]
							)?_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":("" + (album.photos[n - 1].link)),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()],1)}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"border-radius":"0"},attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((4),function(n){return _c('v-col',{key:n,staticClass:"ma-0 pa-0",attrs:{"cols":"6"}},[(
											album.photos &&
												n + 3 <= album.numberOfPhotos &&
												album.photos[n + 2]
										)?_c('v-img',{staticClass:"grey lighten-2",attrs:{"src":("" + (album.photos[n + 2].link)),"aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e()],1)}),1),(album.numberOfPhotos > 7)?_c('v-overlay',{attrs:{"absolute":"","opacity":"0.7","value":true}},[_vm._v(" + "+_vm._s(album.numberOfPhotos - 3)+" ")]):_vm._e()],1)],1)],2),_c('v-card-title',{staticClass:"justify-center py-2 secondary",staticStyle:{"min-height":"80px"}},[_vm._v(" "+_vm._s(album.title)+" ")])],1)],1)}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }