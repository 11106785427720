export const routerTypes = {
	NOT_FOUND: '404',
	O_MNE: 'omne',
	GALERIA: 'galeria',
	GALERIA_DETAIL: 'galeriaDetail',
	CENNIK: 'cennik',
	SPOLUPRACE: 'spoluprace',
	KURZY: 'kurzy',
	OBJEDNAVKY: 'objednavky'
};
