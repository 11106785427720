import { GetterTree } from 'vuex';
import { getterTypes, HomeState } from '@/store/modules/home/types';
import { RootState } from '@/store/types';
import { TextData } from '@/services';

export const getters: GetterTree<HomeState, RootState> = {
	[getterTypes.HOME_DATA](state): HomeState {
		return state;
	},
	[getterTypes.TEXT_BY_KEY]: state => (key: string): TextData => {
		return (
			state.texts.find(t => t.key === key) ??
			TextData.create({
				key,
				value: ''
			})
		);
	}
};
