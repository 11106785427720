/* eslint-disable */
import firebase from 'firebase';
import BaseService from './baseService';

export default class FirebaseService extends BaseService {
	static getData(tableName: string) {
		const data = firebase
			.database()
			.ref(tableName)
			.once('value')
			.then(response => response.val());
		return data;
	}

	static getDataWithLimit(tableName: string, limit?: number) {
		const data = firebase
			.database()
			.ref(tableName)
			.limitToFirst(limit ?? 7)
			.once('value')
			.then(response => response.val());
		return data;
	}

	static setData(tableName: string, data: any) {
		return firebase
			.database()
			.ref(tableName)
			.set(data);
	}

	static pushData(tableName: string, data: any) {
		return firebase
			.database()
			.ref(tableName)
			.push(data);
	}

	static updateData(tableName: string, data: any) {
		return firebase
			.database()
			.ref(tableName)
			.update(data);
	}

	static removeValue(tableName: string, child: string) {
		return firebase
			.database()
			.ref(tableName)
			.child(child)
			.remove();
	}

	static removeData(tableName: string) {
		return firebase
			.database()
			.ref(tableName)
			.remove();
	}

	static login(email: string, password: string) {
		return firebase.auth().signInWithEmailAndPassword(email, password);
	}

	static logout() {
		return firebase.auth().signOut();
	}

	static passwordUpdate(password: string) {
		return firebase.auth().currentUser!.updatePassword(password);
	}

	static actualUser() {
		return firebase.auth().currentUser;
	}

	static createUserWithEmailAndPassword(email: string, password: string) {
		return firebase.auth().createUserWithEmailAndPassword(email, password);
	}

	static passwordResetMail(email: string) {
		return firebase.auth().sendPasswordResetEmail(email);
	}
}
