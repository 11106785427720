export interface LayoutState {
	layout: string;
	loading: boolean;
}

export const actionTypes = {};

export const mutationTypes = {
	SET_LAYOUT: 'SET_LAYOUT',
	SET_LOADING: 'SET_LOADING'
};

export const getterTypes = {
	layout: 'layout'
};
