import { AxiosPromise, AxiosResponse } from 'axios';
import { SendMailResponse, SendMailRequest } from './types';
import BaseService from './baseService';

export default class ContactsService extends BaseService {
	/////////////////////////////////////////
	//                 Mail                //
	/////////////////////////////////////////
	static sendMail(data: SendMailRequest): AxiosPromise<AxiosResponse<SendMailResponse>> {
		return this.post('send-mail', data);
	}
}
