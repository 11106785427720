











































import { Component, Vue } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class Spoluprace extends Vue {
	imgs = [
		{
			id: 0,
			name: 'C 39 ART - Peter Murín',
			src: require('@/assets/imgs/fotografi/39art.png')
		},
		{
			id: 2,
			name: 'Eva Novanská',
			src: require('@/assets/imgs/fotografi/eva.jpg')
		},
		{
			id: 1,
			name: 'Adam Oliva',
			src: require('@/assets/imgs/fotografi/add.jpg')
		}
	];
}
