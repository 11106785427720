import { FileUploadResponse } from './types';
import { AxiosPromise, AxiosResponse } from 'axios';
import BaseService from './baseService';

export default class FileService extends BaseService {
	/////////////////////////////////////////
	//                Files                //
	/////////////////////////////////////////

	static uploadFile(file: File, way: string): AxiosPromise<AxiosResponse<FileUploadResponse>> {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('way', way);
		return this.post('file-upload', undefined, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}

	static deleteFile(filePath: string) {
		return this.post('file-delete', { file: filePath });
	}
}
