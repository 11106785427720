import { TextData } from '@/services';

export interface HomeState {
	text: TextData;
	texts: TextData[];
}

export const actionTypes = {
	LOAD_TEXTS: 'loadTexts'
};

export const mutationTypes = {
	SAVE_TEXTS: 'saveTexts'
};

export const getterTypes = {
	HOME_DATA: 'homeData',
	TEXT_BY_KEY: 'textByKey'
};
