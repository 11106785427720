








import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import { moduleTypes } from '@/store/types';
import { layoutTypes } from './services/types/layoutTypes';
import FirebaseService from './services/firebaseService';

const layoutNamespace = namespace(moduleTypes.LAYOUT);

@Component({
	components: {
		[layoutTypes.DEFAULT]: DefaultLayout,
		[layoutTypes.EMPTY]: EmptyLayout
	}
})
export default class App extends Vue {
	@layoutNamespace.Getter(moduleTypes.LAYOUT) layout!: string;

	mouted() {
		FirebaseService.actualUser();
	}
}
